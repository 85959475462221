import logo from "./logo.svg";
import "./App.css";
import SearchAppBar from "./search-app-bar";
import Typography from "@mui/material/Typography";
import BasicSpeedDial from "./speed-dial";

function App() {
  return (
    <div className="App">
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap"
      />  
        <br></br>
        <Typography
            variant="h2"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" }, fontWeight: "bold"}}
          >
            Global Air Service
          </Typography>
          <Typography
            variant="p"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            Coming soon...
          </Typography>
        <img src="global2.png" alt="Global Air Service" width={400} /> 
        <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            “Once you have tasted flight, you will forever walk the earth with your eyes turned
            skyward, for there you have been, and there you will always long to return.” 
          </Typography>
          <Typography
            variant="small"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            ― Leonardo da Vinci
          </Typography> 
    </div>
  );
}

export default App;
